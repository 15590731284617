import { getHeaderHeight } from '../../utils/functions/header';

const Style = function ({ screens, accentColor }) {
  return (
    <style global jsx>{`
      .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        // height: ${getHeaderHeight(screens)}px;
        // padding: ${screens.sm ? '32px' : '16px 8px 16px 16px'};
        // rgb(12 56 112)
      }
      .titleLogoContainer {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
      }
      .logoContainer {
        width: 30%;
        height: ${getHeaderHeight(screens)}px;
        padding: ${screens.sm ? '16px' : '8px 0'};
        margin-right: ${screens.sm ? 24 : 16}px;
        max-width: 175px;
      }
      .logoSubContainer {
        position: relative;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .logoSubContainer img {
        max-height: ${getHeaderHeight(screens) - 16}px;
      }
      .subHeader {
        flex-grow: 1;
      }
      .title {
        vertical-align: middle;
        font-size: 16px;
        margin-left: 16px;
        margin: 0;
      }
      .subTitle {
        font-size: 20px;
        line-height: 1.2;
        margin-left: 16px;
        margin: 0;
      }
      .animator {
        color: ${accentColor};
        margin-right: 16px;
      }
    `}</style>
  );
};

export default Style;
